@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom Font */
@font-face {
  font-family: 'ArabicFont';
  src: url('./assets/Arabic-font-2013.ttf') format('truetype');
}

/* Global Styles */
body {
  margin: 0;
  text-transform: lowercase;

}

button {
  text-transform: lowercase;
  cursor: pointer;
}


input::placeholder {
  color: #000;
  opacity: 0.5;
  text-transform: lowercase;
}

input,
button {
  width: 100%;
}

.single-line {
  white-space: nowrap;
}

/* Header Styles */
.header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0.5rem 4rem; /* Increased padding */
  /* background-color: #FF5C00; */
}

.header-logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.header-logo-img {
  height: auto;
  max-height: 80px; /* Adjusted for better responsiveness */
  width: auto;
}

/* Navigation Styles */
.navbar-spacing {
  display: flex;
  flex-direction: row;
  gap: 1rem; /* Default spacing */
}

.navbar-link {
  font-size: 0.875rem; /* Default to small size */
  font-family: 'ArabicFont', sans-serif;
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  transition: color 0.3s;
  white-space: nowrap;
}

.navbar-link:hover {
  color: #FFD9A1;
}

.gradient-button {
  background: linear-gradient(to right, #FF9000, #FFC700);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-weight: 600; /* font-semibold */
  transition: color 0.3s;
  width: 100%;
}

.tight-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5; /* Remove padding to make the button tight around the content */
  width: 2rem; /* Set width */
  height: 2rem; /* Set height equal to width to make it square */
  border-radius: 0.5rem; /* Adjust for rounded corners if needed */
  background: black;
  color: white;
  font-weight: 600; /* Adjust font size as needed */
  transition: color 0.3s;
}

.tight-gradient-button:hover {
  color: #FF9000;
}

.gradient-button:hover {
  color: #FF9000;
}

.black-button {
  background: black;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-weight: 600; /* font-semibold */
  transition: color 0.3s;
  width: 100%;
}
/* Responsive Design */

/* Small Screens (up to 768px) */
@media (max-width: 768px) {
  .header-container {
    flex-direction: column;
    align-items: center;
    padding: 1rem 2rem;
  }

  .header-logo {
    flex-direction: column;
    align-items: center;
  }

  .navbar-spacing {
    flex-direction: column;
    align-items: center;
    gap: 1rem; /* Adjust gap for smaller screens */
  }

  .navbar-link {
    font-size: 1rem; /* Increase font size on smaller screens */
  }

  .social-icon {
    max-height: 2rem; /* Adjust icon size on smaller screens */
    cursor:pointer;
  }

  h1 {
    font-size: 2rem;
  }

  p {
    font-size: 1rem;
  }

  .map-logo img {
    max-height: 40px; /* Adjust logo size for smaller screens */
  }
}

/* Medium Screens (768px to 1024px) */
@media (min-width: 768px) and (max-width: 1024px) {
  .header-logo img {
    height: 80px; /* Adjust logo size for medium screens */
  }

  .navbar-spacing {
    gap: 1rem; /* Increase gap for medium screens */
  }

  .navbar-link {
    font-size: 1.25rem; /* Slightly larger text for medium screens */
  }

  h1 {
    font-size: 3rem;
  }

  p {
    font-size: 1.5rem;
  }
  
  .map-logo img {
    max-height: 60px; /* Adjust logo size for medium screens */
  }
}

/* Large Screens (1024px and above) */
@media (min-width: 1024px) {
  .header-logo img {
    height: 100px; /* Adjust logo size for large screens */
  }

  .navbar-spacing {
    gap: 2rem; /* Increase gap for large screens */
  }

  .navbar-link {
    font-size: 1.75rem; /* Larger text for large screens */
  }

  h1 {
    font-size: 4rem;
  }

  p {
    font-size: 2rem;
  }

  .map-logo img {
    max-height: 80px; /* Adjust logo size for large screens */
  }
}

/* Footer Styles */
footer {
  position: relative;
  width: 100%;
  background-color: black;
  color: white;
  padding: 1rem 0;
  overflow: hidden;
  white-space: nowrap;
}

/* Custom Shadow */
.custom-shadow {
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.4));
  z-index: 10;
}

/* Map Component Styles */
.map-container {
  border: 4px solid white; /* Black border */
  overflow:hidden;
}

/* Events Grid */

/* styles.css or your main CSS file */
.map-logo {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  padding: 1rem; /* 4 is usually 1rem in Tailwind, adjust if necessary */
  z-index: 10;
}

.events-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
}

.event-image img {
  width: 100%;
  height: 12rem; /* Adjust height as needed */
  object-fit: cover;
}

/* Ensure there's spacing above and below elements */
.event-details p {
  margin-bottom: 0.5rem;
}

.event-card {
  cursor: pointer;
}

.event-card .event-details {
  padding-bottom: 2rem; /* Ensure enough padding below the details section */
}

.event-card .event-more-info {
  margin-top: 1rem; /* Space between additional info and event details */
}

.marker {
  width: 20px;
  height: 20px;
  background-color: black;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  position: relative;
  z-index: 1; /* Ensure it is on top of other map layers */
}

.marker.selected {
  background-color: #FF9000; /* Highlight selected event marker */
  overflow: hidden;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

.fade-in {
  animation: fadeIn 0.5s ease-out;
}

.slide-in {
  animation: slideIn 0.5s ease-out;
}

.button-animation {
  transition: transform 0.2s ease, color 0.2s ease;
}

.scale-animation:hover {
  transform: scale(1.1);
  color: #FFD9A1; /* Optional: Change color on hover */
}

.button-scale {
  transition: transform 0.2s ease;
}

.scale-animation:hover {
  transform: scale(1.1);
}
